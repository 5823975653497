import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Meta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
import Rollbar from 'rollbar';
import { GlobalsPlugin } from './vue-globals';

declare global {
  interface Window {
    rollbar: typeof Rollbar,
    rollbarIgnore: (...args: any) => boolean;
  }
}

window["rollbar"] = Rollbar;

if (process.env.VUE_APP_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_ANALYTICS,
    router,
  });
}

Vue.use(GlobalsPlugin);
Vue.use(Meta);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app-reviewsStudyportalsCom');
