import Vue from 'vue';
import Router, { Route } from 'vue-router';
import Home from './views/Home.vue';
import Write from './views/Write.vue';
import WriteGSSA from './views/WriteGSSA.vue';

Vue.use(Router);

const getPropsForForm = (route: Route) => {
  return {
    organisation: route.query.organisation,
    utmSource: route.query.utm_source,
    utmMedium: route.query.utm_medium,
    utmCampaign: route.query.utm_campaign,
    utmTerm: route.query.utm_term,
    utmContent: route.query.utm_content,
  };
};

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/write',
      name: 'write',
      component: Write,
      props: (route) => getPropsForForm(route),
    },
    {
      path: '/gssa',
      name: 'gssa',
      component: WriteGSSA,
      props: (route) => getPropsForForm(route),
    },
  ],
  mode: 'history',
});

export default router;
