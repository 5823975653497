
import WriteVue from './Write.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

const URL = (process.env.VUE_APP_URL as string);

@Component({
    components: {
        WriteVue,
    },
})
export default class WriteGSSA extends Vue {

  @Prop({ default: 'Global Student Satisfaction Awards | Share your study experience | Studyportals' })
  private metaTitle!: string;

  // tslint:disable-next-line:max-line-length
  @Prop({ default: 'Leave a review about your university experience and let’s make education more transparent for students all around the world!' })
  private metaDescription!: string;

  @Prop({ default: `${URL}/img/global-student-satisfaction-awards-2021-girl.jpg` })
  private image1!: string;

  @Prop({ default: `${URL}/img/global-student-satisfaction-awards-2021-boy.jpg` })
  private image2!: string;

  @Prop({ default: '' }) private organisation!: string;
  @Prop({ default: '' }) private utmSource!: string;
  @Prop({ default: '' }) private utmMedium!: string;
  @Prop({ default: 'gssa' }) private utmCampaign!: string;
  @Prop({ default: '' }) private utmTerm!: string;
  @Prop({ default: '' }) private utmContent!: string;
}
