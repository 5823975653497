import {Component, Vue, Prop} from 'vue-property-decorator';
import {Meta} from '@/decorators';
import {
  injectStyleSheetToHead,
  injectScriptToBody,
} from '@studyportals/microservice-injector';
import fetch from 'cross-fetch';

const VUE_APP_WIDGET_MANIFEST = (process.env.VUE_APP_WIDGET_MANIFEST as string);
const FB_APP_ID = (process.env.VUE_APP_FB_APP_ID as string);
const URL = (process.env.VUE_APP_URL as string);

type AssetPromise = Promise<void>;
type AssetCallback = (src: string) => AssetPromise;

@Component({})
export default class Write extends Vue {

  @Prop({default: 'Share your study experience | Studyportals'})
  private metaTitle!: string;

  // tslint:disable-next-line:max-line-length
  @Prop({default: 'Leave a review about your university experience and let’s make education more transparent for students all around the world!'})
  private metaDescription!: string;

  @Prop({default: `${URL}/img/share-your-experience-studyportals-phone.jpg`})
  private image1!: string;

  @Prop({default: `${URL}/img/share-your-experience-studyportals-tablet.jpg`})
  private image2!: string;

  @Prop({default: ''}) private organisation!: string;
  @Prop({default: ''}) private utmSource!: string;
  @Prop({default: ''}) private utmMedium!: string;
  @Prop({default: ''}) private utmCampaign!: string;
  @Prop({default: ''}) private utmTerm!: string;
  @Prop({default: ''}) private utmContent!: string;

  @Meta
  public myMetaConfig() {

    const getImageData = (image: string) => {
      if (!image) {
        return [];
      }

      // The meta tags need to be unique or they will be de-duplicated.
      const uniqueId = '_' + Math.random().toString(36).substr(2, 9);

      return [
        {property: 'og:image', content: image, uniqueId},
        {property: 'og:image:type', content: 'image/jpeg', uniqueId},
        {property: 'og:image:width', content: '1200', uniqueId},
        {property: 'og:image:height', content: '630', uniqueId},
        {property: 'og:image:alt', content: this.metaTitle, uniqueId},
      ];
    };

    return {
      title: this.metaTitle,
      meta: [
        {name: 'description', content: this.metaDescription},

        {property: 'fb:app_id', content: FB_APP_ID},

        {property: 'og:url', content: `${URL}${this.$route.fullPath}`},
        {property: 'og:type', content: 'website'},
        {property: 'og:title', content: this.metaTitle},
        {property: 'og:description', content: this.metaDescription},

        ...getImageData(this.image1),
        ...getImageData(this.image2),
      ],
    };
  }

  private mounted() {
    if (window && window.hasOwnProperty('__PRERENDER_INJECTED') === true) {
      // Prevent loading the Reviews-Form during pre-rendering. This solves a bug of loading the widget twice.
      return;
    }

    return fetch(VUE_APP_WIDGET_MANIFEST)
      .then((response) => response.json())
      .then((json) => {
        let promiseChain = Promise.resolve();

        // Load in Vue library
        promiseChain = promiseChain.then(() => {
          return injectScriptToBody('https://cdn.jsdelivr.net/npm/@studyportals/vue-config@3.0.0/dist/library.min.js');
        });

        promiseChain = promiseChain.then(() => {
          return injectScriptToBody('https://cdn.jsdelivr.net/npm/@studyportals/multiselect-dll@2.2.2/dist/multiselect.min.js');
        });

        promiseChain = promiseChain.then(() => {
          return injectScriptToBody('https://cdn.jsdelivr.net/npm/@studyportals/data-storage-dll@2.0.0/dist/dataStorage.min.js');
        });

        promiseChain = this.injectAsset(json.assets.js, promiseChain, injectScriptToBody);
        promiseChain = this.injectAsset(json.assets.css, promiseChain, injectStyleSheetToHead);

        return promiseChain;
      })
      .then(() => {
        this.mountWidget();
      });
  }

  private injectAsset(assets: string[], promiseChain: AssetPromise, callback: AssetCallback): AssetPromise {
    return assets.reduce((acc: AssetPromise, asset: any) => {
      return acc.then(() => callback(asset.url));
    }, promiseChain);
  }

  private mountWidget() {
    const formPlaceholder = document.getElementById('app-form');
    const routerReviews = (window as any).Reviews;
    const formWidget = routerReviews.ReviewsFormNew;
    formWidget.$mount(formPlaceholder);
    formWidget.$router.push({
      name: 'ReviewsFormNew',
      query: {
        organisation: this.organisation,
        utm_source: this.utmSource,
        utm_medium: this.utmMedium,
        utm_campaign: this.utmCampaign,
        utm_term: this.utmTerm,
        utm_content: this.utmContent,
      },
    });
  }
}
