import { VueConstructor, PluginObject } from "vue";

const stage = process.env.VUE_APP_STAGE ? process.env.VUE_APP_STAGE : "tst";

const rollbarConfig = {
	accessToken: "10c59b030ff34446949678e8a268745b",
	captureUncaught: false,
	captureUnhandledRejections: false,
	payload: {
		environment: stage
	},
	checkIgnore: window["rollbarIgnore"],
};

const GlobalsPlugin: PluginObject<unknown> = {

	install(Vue: VueConstructor) {
		const rollbar = (window && window["rollbar"] && window["rollbarIgnore"]) ? new window["rollbar"](rollbarConfig) : null;

		Vue.config.errorHandler = function (error, vm, info) {
			if(rollbar && stage === 'prd') {
				rollbar.error(error);
			}
			
			throw error;
		}
	}
};

export { GlobalsPlugin };
